/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
function n() {
  return new Float32Array(2);
}
function r(n) {
  const r = new Float32Array(2);
  return r[0] = n[0], r[1] = n[1], r;
}
function t(n, r) {
  const t = new Float32Array(2);
  return t[0] = n, t[1] = r, t;
}
function e(n, r) {
  return new Float32Array(n, r, 2);
}
function o() {
  return n();
}
function u() {
  return t(1, 1);
}
function c() {
  return t(1, 0);
}
function a() {
  return t(0, 1);
}
const i = o(),
  f = u(),
  l = c(),
  s = a(),
  y = Object.freeze(Object.defineProperty({
    __proto__: null,
    ONES: f,
    UNIT_X: l,
    UNIT_Y: s,
    ZEROS: i,
    clone: r,
    create: n,
    createView: e,
    fromValues: t,
    ones: u,
    unitX: c,
    unitY: a,
    zeros: o
  }, Symbol.toStringTag, {
    value: "Module"
  }));
export { f as ONES, l as UNIT_X, s as UNIT_Y, i as ZEROS, r as clone, n as create, e as createView, t as fromValues, u as ones, c as unitX, a as unitY, y as v, o as zeros };